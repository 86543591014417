:root {
  --bs-white: rgba(255, 255, 255, 0.5);
}

// Here you can add other styles
.font-18 {font-size: 18px;}
.font-28 {font-size: 18px;}
.me-2 {margin-right:10px;}
header {
  .c-avatar {
    width: auto;
    height: 36px;
    min-width: 36px;
    .c-avatar-img {
       height: 100%;
       object-fit: cover;
    }
  }
}

.c-sidebar {
  .globalIcon {
    margin-top: -20px;
    flex: 0 0 30px;
    height: 40px;
    flex-basis: 30px;
    margin-left: 0;
    margin-right: 10px;    
    path {
      fill: var(--bs-white);
    }
  }
}

title {
  .title,
    .title:hover,
    .title:active,
    .title:visited,
    .title:focus {
      text-decoration: none;
      cursor: pointer;
      color: blue;
    }
}


h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  text-align: center;
}

html {
  overflow-x: hidden;
}

.cstm-text {
  textarea {
    overflow: auto;
    resize: vertical;
    width: 100%;
  }
}

.c-body {
  .c-main {
    .container-fluid { 
      .fade {
        .row.cstm-add-btn {
          justify-content: flex-end !important;
          margin: 0 !important;
        }
      }
      .card-body {
        @media (min-width:320px) and (max-width: 1199px){
          overflow-x: auto;
        }
      }
      .table-outline {
        @media (min-width:320px) and (max-width: 1199px){
          min-width: 800px;
          display: block !important;
        }
        td {
          .mb-3.mb-xl-0.col-sm-4.col-md-2.col-xl {
            width: 100%;
            flex: 0 0 100%;
            max-width: 100%;
            padding: 0;
          }
        }
      }
    }
  }
}
@media (max-width: 767px){
  .c-header-brand {
    width: 140px;
  }
  html:not([dir="rtl"]) {
    .c-header-nav {
      min-height: 45px;
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
      .c-header-nav-link {
        padding-right: 0.2rem;
        padding-left: 0.2rem;
      }
    }
  }
  header {
    .c-avatar {
      height: 28px;
      min-width: 28px;
    }
  }
}

html:not([dir="rtl"]) {
  .c-footer {
    @media (max-width: 767px){
    justify-content: center;
      .mfs-auto {
        margin-left: 0 !important;
      }
    }
  }
}
.c-sidebar-nav.h-100 .c-sidebar-nav-dropdown-toggle::after {
  transform: rotate(-180deg);
}

.mendatory {
  color: red;
  font-size: 20px;
}

.avatarimg {
  width: 38px !important;
  height: 38px !important;
}